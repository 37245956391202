import maintenance from './img1.jpg'
import './App.css';

function App() {
  return (
    <div className="background">
      <div className="content">
      </div>
    </div>
  );
}

export default App;
